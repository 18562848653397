<template>
  <div>
    <div style="width: 100%">
      <img style="width: 100%" src="./img/顶部背景.png" alt="" />
    </div>
    <!-- 大模块 -->
    <div class="bigCss">
      <!-- 公司介绍 -->
      <!-- 公司介绍 -->
      <div class="biaoti">
        <img style="margin-right: 16px" src="./img/公司介绍.png" alt="" />
        公司介绍
      </div>
      <div
        style="
          font-size: 28px;
          font-family: Microsoft YaHei-Bold, Microsoft YaHei;
          font-weight: bold;
          color: rgba(0, 0, 0, 0.05);
          margin-top: 4px;
        "
      >
        Company Introduction
      </div>
      <img
        style="width: 1386px; margin-top: 28px; margin-bottom: 60px"
        src="./img/公司图.png"
        alt=""
      />
      <!-- <div class="zhengwen">
        武汉烽火富华电气有限责任公司于2008年10月成立，注册资本壹亿壹仟捌佰万元，致力于成为智慧能源与智慧城市领域领先的产品与解决方案提供商。
      </div> -->
      <div class="zhengwen">
        武汉烽火富华电气有限责任公司于2008年10月成立，注册资本壹亿壹仟捌佰万元，致力于成为智慧能源与智慧城市领域领先的产品与解决方案提供商。公司是武汉东湖高新技术开发区“3551人才”企业、“高新技术企业”、武汉市“千企万人”企业、武汉市“金种子”企业，武汉市科技创新企业中心，国家级专精特新“小巨人”企业。
      </div>
      <div class="zhengwen">
        公司秉持“平等、互信、合作、共赢”的价值理念，汇集了⼀批电子、计算机、电气自动化、新能源等领域的高科技⼈才，与清华大学、华中科技大学、武汉大学、国家光电研究中心、中科院等高等院校与研究院所均建立了紧密的产学研合作关系，成果转化效果显著，开发出的产品均拥有自主知识产权。
      </div>
      <div class="zhengwen">
        公司聚焦智慧能源与智慧城市两大领域，业务覆盖储能（包括集中式储能、工商业储能、分布式储能、户用储能、移动储能等）、电动汽车充电桩（直流充电桩与交流充电桩等）、换电站、新能源发电、智能配用电、智慧建筑、智慧安防、智慧园区、智慧交通等相关业务的产品开发及整体解决方案。
        作为国家级高新技术企业，公司始终坚持布局长远、追求卓越，以持续创新赋能高质量发展，积极培育人工智能、大数据、云计算、区块链等新⼀代信息技术，成为智慧能源与智慧城市领域领先的产品及解决方案提供商。
      </div>

      <!-- 地图模块 -->
      <!-- 地图模块 -->
      <div class="biaoti">
        <img style="margin-right: 16px" src="./img/联系方式.png" alt="" />
        联系方式
      </div>
      <div
        style="
          font-size: 28px;
          font-family: Microsoft YaHei-Bold, Microsoft YaHei;
          font-weight: bold;
          color: rgba(0, 0, 0, 0.05);
          margin-top: 4px;
        "
      >
        Contact
      </div>
      <div class="ditu">
        <iframe style="border-radius: 8px 8px 8px 8px" width="1314" height="498" frameborder='0' scrolling='no' marginheight='0' marginwidth='0' src="https://surl.amap.com/72aaSDBq11k"></iframe>
        <div class="rightTop">
          <div class="rBox" v-for="(item, index) in this.dituData" :key="index">
            <img :src="item.url" alt="" />
            <div class="titleBOX">
              <div class="tName">{{ item.title }}</div>
              <div class="tData">{{ item.content }}</div>
            </div>
          </div>
        </div>
        <div
          @mouseover="mouseOver"
          @mouseleave="mouseLeave"
          class="rightBottom"
        >
          <img style="margin-left: 24px" src="./img/码小.png" alt="" />
          <div style="margin-left: 24px" class="tData">点击关注微信公众号</div>
        </div>
        <div v-show="isShow" class="erweima">
          <img style="margin-top: 24px" src="./img/码大.png" alt="" />
          <div class="tName">微信公众号</div>
        </div>
      </div>

      <!-- 资质荣誉 -->
      <!-- 资质荣誉 -->
      <div class="zzDiv">
        <div class="biaoti">
          <img style="margin-right: 16px" src="./img/资质.png" alt="" />
          资质荣誉
        </div>
        <div
          style="
            font-size: 28px;
            font-family: Microsoft YaHei-Bold, Microsoft YaHei;
            font-weight: bold;
            color: rgba(0, 0, 0, 0.05);
            margin-top: 4px;
          "
        >
        Qualification honor
        </div>

        <el-carousel  @change="handleCarouselChange" style="width: 1314px;background-color: rgba(255, 255, 255, 0.80);margin-top: 60px;" :interval="4000" type="card" height="512px">
    <el-carousel-item style="margin-top: 0px;" v-for="item in this.imgData" :key="item">
      <div style="display: flex;
    flex-direction: column;
    align-items: center;padding-top: 60px;">
        <img  ref="imgH" style="width: 230px;height: 345px;" :src="item.url" alt="" >

      </div>
      
    </el-carousel-item>
    <h3 class="imgName" >{{imgname}}</h3>
  </el-carousel>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      imgname:'质量管理体系认证证书',
      isShow: false,
      dituData: [
        {
          url: require("./img/地址.png"),
          title: "地址",
          content: "武汉市东湖新技术开发区邮科院路88号",
        },
        {
          url: require("./img/邮箱.png"),
          title: "邮箱",
          content: "AD@ffepower.com",
        },
        {
          url: require("./img/电话.png"),
          title: "市场营销部",
          content: "+86-27-87693118",
        },
        {
          url: require("./img/电话.png"),
          title: "综合管理部",
          content: "+86-27-87693119",
        },
      ],
      imgData:[
        {
          url: require("./img/证书1.png"),
        },
        {
          url: require("./img/证书2.png"),
        },
        {
          url: require("./img/证书3.png"),
        },
        {
          url: require("./img/证书4.png"),
        },
        {
          url: require("./img/证书5.png"),
        },
        {
          url: require("./img/证书6.png"),
        },
      ]
    };
  },
  methods: {
    handleCarouselChange(index) {
    if (index=='0') {
      this.imgname='质量管理体系认证证书'
    }else if (index=='1') {
      this.imgname='知识产权管理体系认证证书'
    }else if (index=='2') {
      this.imgname='企业3A证书'
    }
    else if (index=='3') {
      this.imgname='军运会感谢信'
    }
    else if (index=='4') {
      this.imgname='江夏疫情指挥部感谢信'
    }
    else if (index=='5') {
      this.imgname='荣誉证书'
    }else{
      this.imgname='荣誉证书'
    }
    
    
  },
    mouseOver() {
      this.isShow = true;
    },
    // 鼠标移出
    mouseLeave(val) {
      this.timeoutId = setTimeout(() => {
        this.isShow = false;
      }, 500); // 延迟500毫秒后隐藏导航栏
      // this.leftBox=[]
    },
  },
  mounted(){
    this.handleCarouselChange()
  }
};
</script>

<style scoped>
.bigCss {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  background: #ffffff;
}
.biaoti {
  display: flex;
  align-items: center;
  margin-top: 100px;
  font-size: 30px;
  font-family: Microsoft YaHei-Bold, Microsoft YaHei;
  font-weight: bold;
  color: #000000;
}
.zhengwen {
  text-indent: 2em;
  width: 1314px;
  font-size: 18px;
  font-family: Microsoft YaHei-Regular, Microsoft YaHei;
  font-weight: 400;
  color: #63798d;
  line-height: 42px;
}
.ditu {
  margin-top: 46px;
  position: relative;
}
.rightTop {
  width: 430px;
  height: 350px;
  background: #ffffff;
  border-radius: 8px 8px 8px 8px;
  position: absolute;
  top: 24px;
  right: 24px;
  z-index: 2;
}
.rightBottom {
  width: 430px;
  height: 84px;
  background: #ffffff;
  border-radius: 8px 8px 8px 8px;
  position: absolute;
  bottom: 24px;
  right: 24px;
  z-index: 2;

  display: flex;
  align-items: center;
}

.rBox {
  margin-top: 32px;
  margin-left: 24px;
  display: flex;
  align-items: center;
}
.titleBOX {
  margin-left: 24px;
}
.tName {
  font-size: 18px;
  font-family: Microsoft YaHei-Regular, Microsoft YaHei;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.4);
}
.tData {
  font-size: 18px;
  font-family: Microsoft YaHei-Bold, Microsoft YaHei;
  font-weight: bold;
  color: rgba(0, 0, 0, 0.9);
}
.erweima {
  display: flex;
  align-items: center;
  flex-direction: column;

  position: absolute;
  bottom: 24px;
  right: -254px;
  width: 230px;
  height: 230px;
  background: #ffffff;
  box-shadow: 0px 0px 10px 1px rgba(0, 0, 0, 0.1);
  border-radius: 12px 12px 12px 12px;
}
.zzDiv {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 1920px;
  height: 920px;
  background-image: url("./img/资质荣誉.png");
}

.el-carousel__item h3 {
    color: #475669;
    font-size: 14px;
    opacity: 0.75;
    line-height: 200px;
    margin: 0;
  }
  
  .el-carousel__item:nth-child(2n) {
    background-color: rgba(255, 255, 255, 0.1);
  }
  
  .el-carousel__item:nth-child(2n+1) {
    background-color: rgba(255, 255, 255, 0.1);
  }
  .imgName{
  position: absolute;
  top: 449px;
  left: 50%;
  transform: translateX(-50%);

  font-size: 22px;
font-family: Microsoft YaHei-Regular, Microsoft YaHei;
font-weight: 400;
color: rgba(0, 0, 0, 0.9);
}
</style>